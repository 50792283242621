import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { createSelector } from 'reselect';
import { Observable, of as observableOf, throwError as observableThrowError } from 'rxjs';
import { catchError, delay, map, switchMapTo, tap } from 'rxjs/operators';

import { maxToday, periodFilter } from '../../../shared/date.helper';
import { getAuthenticatedDepartmentIds } from '../../auth/auth.service';
import { AppState } from '../../index';
import { departmentFilter, getSelectedDepartmentIds } from '../../selected-departments/selected-departments.service';
import { mapEntity } from '../../shared/entity.helper';
import { DaylogAction } from './daylog.action';
import { DaylogApi, DaylogsLoadRequest } from './daylog.api';
import { DaylogModel, DaylogState } from './daylog.model';

@Injectable()
export class DaylogService {
  public constructor(
    private store: Store<AppState>,
    private api: DaylogApi,
  ) {}

  public load(requestData: DaylogsLoadRequest, updateStore = true) {
    return this.api.load(requestData, DaylogAction.load(requestData)).pipe(
      map((response) => {
        if (updateStore) {
          this.store.dispatch(DaylogAction.loadSuccess(response));
        }

        return response;
      }),
      catchError((response) => {
        this.store.dispatch(DaylogAction.loadFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  public batch(daylogData) {
    const data = { Log: daylogData };

    return this.api.batch(data).pipe(
      tap((response) => {
        this.store.dispatch(DaylogAction.saveSuccess(response));
      }),
      catchError((response) => {
        this.store.dispatch(DaylogAction.saveFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  public save(daylogData): Observable<any> {
    return this.api.add(daylogData, DaylogAction.save(daylogData)).pipe(
      map((response) => {
        this.store.dispatch(DaylogAction.saveSuccess(response));
        return observableOf(response);
      }),
      catchError((response) => {
        this.store.dispatch(DaylogAction.saveFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  /**
   * The delayedSave method was added to solve the following problem:
   * When editing a timesheet the user can change a value and close the day while
   * the element has not yet blurred. This value will not be correctly saved.
   * The delay makes sure the timesheet is first saved, then the day is closed.
   * Because the loading indicator is saved in the store, the save action
   * must first be dispatched before the delay can be added.
   *
   * @param daylogData, data necessary to open/close days
   * @returns {Observable<any>}
   */

  public delayedSave(daylogData): Observable<any> {
    this.store.dispatch(DaylogAction.save(daylogData));

    return observableOf({}).pipe(
      delay(300),
      switchMapTo(this.api.add(daylogData)),
      tap((response) => {
        this.store.dispatch(DaylogAction.saveSuccess(response));
      }),
      catchError((response) => {
        this.store.dispatch(DaylogAction.saveFailed(response));
        return response;
      }),
    );
  }
}

export const departmentDayIsClosed = (date: string, departmentId: string, daylogs: DaylogModel[]): boolean => {
  const matchingLog = daylogs.find((daylog) => daylog.date === date && daylog.department_id == departmentId);

  if (!matchingLog) {
    return false;
  }

  return !!matchingLog.finished_timesheet;
};

export const getDaylogState = (appState: AppState): DaylogState => appState.orm.daylogs;

export const getDaylogEntities = createSelector(getDaylogState, (state) => state.itemsById);
export const getDaylogs = createSelector(getDaylogState, (state: DaylogState) => Object.values(state.itemsById));

export const getSelectedDayLogs = createSelector(getDaylogs, getAuthenticatedDepartmentIds, (dayLogs, departmentIds) =>
  dayLogs.filter(departmentFilter(departmentIds)),
);

export const getDaylog = (departmentDate: string) =>
  createSelector(getDaylogEntities, (entities) => mapEntity(departmentDate, entities));

export const getDaylogsForPeriodAndSelectedDepartments = (minDate, maxDate, notAfterToday = true) =>
  createSelector(getDaylogs, getSelectedDepartmentIds, (daylogs, departmentIds) =>
    filterDaylogsByPeriodAndDepartment(daylogs, minDate, maxDate, notAfterToday, departmentIds),
  );

export const getDaylogsForPeriodAndDepartment = (departmentId, minDate, maxDate, notAfterToday = true) =>
  createSelector(getDaylogs, (daylogs) =>
    filterDaylogsByPeriodAndDepartment(daylogs, minDate, maxDate, notAfterToday, [departmentId]),
  );

function filterDaylogsByPeriodAndDepartment(daylogs, minDate, maxDate, notAfterToday, departmentIds) {
  maxDate = notAfterToday ? maxToday(maxDate) : maxDate;

  const departmentFilterFn = departmentFilter(departmentIds);
  const periodFilterFn = periodFilter(minDate, maxDate);

  const filterFunc = (daylog) => departmentFilterFn(daylog) && periodFilterFn(daylog);

  return daylogs.filter(filterFunc);
}
