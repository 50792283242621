import { UnsafeAction as Action } from '../../interfaces';

const ACTION_PREFIX = '[Daylog]';

export const daylogActionType = {
  LOAD: `${ACTION_PREFIX} Load`,
  LOAD_SUCCESS: `${ACTION_PREFIX} Load Success`,
  LOAD_FAILED: `${ACTION_PREFIX} Load Failed`,

  SAVE: `${ACTION_PREFIX} Save`,
  SAVE_SUCCESS: `${ACTION_PREFIX} Save Success`,
  SAVE_FAILED: `${ACTION_PREFIX} Save Failed`,
};

export class DaylogAction {
  static load(requestData): Action {
    return {
      type: daylogActionType.LOAD,
      payload: requestData,
    };
  }

  static loadSuccess(daylog): Action {
    return {
      type: daylogActionType.LOAD_SUCCESS,
      payload: daylog,
    };
  }

  static loadFailed(err): Action {
    return {
      type: daylogActionType.LOAD_FAILED,
      payload: err,
    };
  }

  static save(daylogData): Action {
    return {
      type: daylogActionType.SAVE,
      payload: daylogData,
    };
  }

  static saveSuccess(daylogResponse): Action {
    return {
      type: daylogActionType.SAVE_SUCCESS,
      payload: daylogResponse,
    };
  }

  static saveFailed(err): Action {
    return {
      type: daylogActionType.SAVE_FAILED,
      payload: err,
    };
  }
}
